import React from 'react';
import { Link } from 'gatsby';
import 'assets/scss/komsek.scss';

import SEO from 'components/seo';

const BackLink = () => {
  if (typeof window !== 'undefined') {
    if (typeof window.history !== 'undefined') {
      return (
        <>
          <a href="#back" onClick={(e) => {
              e.preventDefault();
              window.history.back();
            }}>zpět</a>
          {' nebo '}
        </>
      );
    }
  }

  return '';
};

const NotFound = () => {
  return (
    <>
      <SEO title="Chyba 404" />
      <article className="fullscreen">
        <div className="container">
          <div className="columns">
            <div className="column col-6 col-md-9 col-sm-12" style={{marginTop: "25%"}}>
              <h1>404 Not Found</h1>
              <p>
                Stránka, na kterou se snažíte přistoupit, nebyla nalezena.
                Zkuste jít třeba <BackLink />
                na <Link to='/'>naši úvodní stránku</Link>.
              </p>
            </div>
          </div>
        </div>
      </article>
    </>
  );
};

export default NotFound;
